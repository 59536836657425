import React, { FC, useState, useMemo, useEffect, ReactNode } from 'react';
import { RoutingContext, RoutingOptions } from '../contexts';

export type RouteHandler = FC<{ path: string[] }>;
export type Route = [RegExp, RouteHandler];

export type RouterProps = {
  routes: Route[];
  NotFound: RouteHandler;
  children?: ReactNode;
};

const getRoute = (routes: Route[], path: string) =>
  routes.find(([pattern]) => path.match(pattern));

declare global {
  interface Window {
    gtag: any;
  }
}

export const Router: FC<RouterProps> = ({ routes, NotFound, children }) => {
  const [{ path, route }, setState] = useState<{
    path: string;
    route: Route | undefined;
  }>(() => ({
    path: window.location.pathname,
    route: getRoute(routes, window.location.pathname),
  }));

  useEffect(() => {
    // list to pop state events and update
    const update = () => {
      setState({
        path: window.location.pathname,
        route: getRoute(routes, window.location.pathname),
      });
    };

    window.addEventListener('popstate', update);
    return () => window.removeEventListener('popstate', update);
  }, [routes]);

  const routingOptions = useMemo<RoutingOptions>(
    () => ({
      route(path: string): void {
        if (window.gtag) {
          window.gtag('set', 'page_path', '/new-page.html');
          window.gtag('event', 'page_view');
        }

        const route = getRoute(routes, path);
        window.history.pushState(null, '', path);
        setState({ path, route });
      },
    }),
    [routes],
  );

  const Component = route ? route[1] : NotFound;

  return (
    <RoutingContext.Provider value={routingOptions}>
      <Component key={path} path={path.slice(1).split('/')} />
      {children}
    </RoutingContext.Provider>
  );
};

import React, { FC } from 'react';
import { Texture } from './Texture';
import { hyperspace } from '../../textures';
import { Footer } from './Footer';
import { Card } from './Card';

export type HomePageProps = {};

export const HomePage: FC<HomePageProps> = () => {
  return (
    <div>
      <Texture texture={hyperspace} animate={true} />
      <div
        style={{
          width: '100vw',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '36px',
          padding: '144px 0',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            margin: '12px',
            width: 'calc(100vw - 24px)',
            maxWidth: '592px',
            position: 'relative',
          }}
        >
          <svg
            style={{
              width: '100%',
              position: 'relative',
            }}
            viewBox="0 0 51.2 7.6"
          >
            <text
              x="0"
              y="7.3"
              fill="white"
              fontFamily="monogram"
              fontWeight="bold"
            >
              INVADERS!
            </text>
          </svg>
        </div>
        <a className="button" href="https://app.invaders.wtf/">
          PLAY NOW
        </a>
        <Card>
          <p>
            INVADERS! is an on-chain strategy game of resource extraction and
            piracy.
          </p>
          <p>
            It is played with NFTs and the EL69 ERC-20 token. Because these
            resources are limited, holders control access and can buy and sell
            their seat at the table. Playing invaders offers a chance to earn
            EL69, but puts planets at risk.
          </p>
          <p>
            No servers are involved, INVADERS! can be played by directly
            interacting with smart contracts on the polygon blockchain, or
            through a web UI with maximalist generative art.
          </p>
          <p>
            Read more about strategy and how to play in the{' '}
            <a href="https://invaders.gitbook.io/invaders/">guide</a>.
          </p>
        </Card>
        <Card>
          <p>
            Star Ships were airdropped to{' '}
            <a
              href="https://opensea.io/collection/pixelglyphs"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pixelglyph
            </a>{' '}
            holders and can be purchased on{' '}
            <a
              href="https://opensea.io/collection/star-ships-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              OpenSea
            </a>
            .
          </p>
          <p>
            Planets can be{' '}
            <a href="https://app.invaders.wtf/mint-planet">minted</a> using
            EL69, or purchased on{' '}
            <a
              href="https://opensea.io/collection/pixelglyph-planets"
              target="_blank"
              rel="noopener noreferrer"
            >
              OpenSea
            </a>
            .
          </p>
          <p>
            EL69 is traded on{' '}
            <a
              href="https://app.sushi.com/trident/swap?tokens=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&tokens=0xAC05696A96fbF947200876ABb8e71b9e18Ef3D6A"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sushi
            </a>
            .
          </p>
        </Card>
        <Footer />
      </div>
    </div>
  );
};

import React, { FC } from 'react';

const links: [href: string, text: string, props?: any][] = [
  [
    'https://medium.com/@nftboi/pixelglyph-play-to-earn-game-on-polygon-7aa88b00c201',
    'Litepaper',
    { target: '_blank', rel: 'noopener noreferrer' },
  ],
  [
    'https://discord.gg/zPRfPxH3EY',
    'Discord',
    { target: '_blank', rel: 'noopener noreferrer' },
  ],
  [
    'https://twitter.com/pixelglyphs',
    'Twitter',
    { target: '_blank', rel: 'noopener noreferrer' },
  ],
  ['/terms', 'Terms'],
  [
    'https://opensea.io/collection/pixelglyphs',
    'Pixelglyphs',
    { target: '_blank', rel: 'noopener noreferrer' },
  ],
];

export const Footer: FC = () => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(4em, 1fr))',
      gap: '1.5em',
      maxWidth: 700,
      width: '100vw',
      margin: '0 auto',
      padding: '36px',
      fontSize: '36px',
      textAlign: 'center',
      position: 'relative',
      boxSizing: 'border-box',
    }}
  >
    {links.map(([href, text, props = {}], i) => (
      <div key={i}>
        <a href={href} {...props}>
          {text}
        </a>
      </div>
    ))}
  </div>
);

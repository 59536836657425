let _seed = Math.random() * 4294967296;

export const seed = (num: number) => (_seed = num);

const _rand = (): number => {
  let t = (_seed += 0x6d2b79f5);
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
};

export const rand = (
  min: number = 0,
  max: number = 1,
  tilt: number = 1,
): number => {
  return min + Math.pow(_rand(), 1 / tilt) * (max - min);
};

export const randInt = (min?: number, max?: number, tilt?: number) =>
  Math.floor(rand(min, max, tilt));

export const randBit = () => Math.round(_rand());

export const randColor = (
  min: number = 0,
  max: number = 256,
): [number, number, number] => [
  randInt(min, max),
  randInt(min, max),
  randInt(min, max),
];

export const randColorString = (): string => `rgb(${randColor().join(',')})`;

export const randRotation = (): [number, number, number] => [
  _rand() * 2 * Math.PI,
  _rand() * 2 * Math.PI,
  _rand() * 2 * Math.PI,
];

export const randMember = <T>(arr: Array<T>): T =>
  arr[Math.floor(_rand() * arr.length)];

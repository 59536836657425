import React, { FC, useEffect, useRef } from 'react';
import { GenerativeTexture } from '../../types';

export type TextureProps = {
  texture: () => GenerativeTexture;
  animate: boolean;
};

export const Texture: FC<TextureProps> = ({ texture, animate }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    const gt = texture();
    gt.canvas.style.imageRendering = 'pixelated';
    const onResize = () => {
      if (ref.current) {
        gt.canvas.style.width = `${ref.current.offsetWidth}px`;
        gt.canvas.style.height = `${ref.current.offsetHeight}px`;
      }
    };
    onResize();
    window.addEventListener('resize', onResize);

    ref.current.appendChild(gt.canvas);

    let alive = true;
    let last = Date.now();
    const step = () => {
      if (!animate) return;
      const now = Date.now();
      const delta = (now - last) / 1000;
      last = now;
      gt.step(delta);
      if (alive) requestAnimationFrame(step);
    };

    requestAnimationFrame(step);
    return () => {
      alive = false;
      window.removeEventListener('resize', onResize);
    };
  }, [texture, animate]);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
      ref={ref}
    />
  );
};

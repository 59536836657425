import React, { FC } from 'react';
import { Router } from './components';
import { HomePage, TermsPage, NotFoundPage } from './pages';

export type AppProps = {};

export const App: FC<AppProps> = () => (
  <Router
    routes={[
      [/^\/$/, HomePage],
      [/^\/terms$/, TermsPage],
    ]}
    NotFound={() => <NotFoundPage />}
  />
);

import React, { FC } from 'react';

export type NotFoundPageProps = {};

export const NotFoundPage: FC<NotFoundPageProps> = () => (
  <div style={{ margin: 48 }}>
    <h1 style={{ fontSize: '96px' }}>404</h1>
    <a href="/" style={{ fontSize: '36px' }}>
      home
    </a>
  </div>
);
